import React, { createContext, useContext, useState, useEffect } from "react";
import { sendGetRequest, sendPostRequest } from "../../utils/request";
const StoreContext = createContext([{}, () => {}]);

export function WithStore({ children }) {
  const [store, setStore] = useState({
    loading: true,
    context: undefined
  });

  const fetchRemoteConfig = async () => {
    const behavior = await sendPostRequest(
      "/v1/user/showBehavior", 
      { "id" : "" }, 
      "fetch remote config"
    );

    setStore(curStore => ({
      ...curStore,
      loading: false,
      context : {
        behavior
      }
    }));
  };

  useEffect(() => {
    fetchRemoteConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !store.loading 
      && 
    store.context != undefined
      &&
      <StoreContext.Provider value={[store, setStore]}>
        {children}
      </StoreContext.Provider>
  );
}

export function useStore() {
  return useContext(StoreContext);
}
