import "./index.css";
import React from "react";
import OneClick from "./OneClick";

export default function HomePage() {
    return (
      <div className="home-page">
        <OneClick />
      </div>
    );
}