import React, { useState } from "react";
import Headbar from "./Headbar";
import './index.css';

export default function AppLayout({ children }) {
  const [isSidebarExtended, setIsSidebarExtended] = useState(true);

  return (
    <Headbar
      isSidebarExtended={isSidebarExtended}
      setIsSidebarExtended={setIsSidebarExtended}
    />
  );
}
