import React from "react";
import { WithStore } from "./components/ReactHooks/useStore";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import AppLayout from "./components/AppLayout";
import './App.css';

function App() {
  return (
    <WithStore>
    <div className="App">
        <Router>
          <AppLayout />
          <Routes>
              <Route exact path="/" element={<HomePage/>}/>
              {/* <Route exact path="/search">
                <AppLayout>
                  <SearchPage />
                </AppLayout>
              </Route>
              <Route exact path="/newpet">
                <AppLayout>
                  <PostPage />
                </AppLayout>
              </Route> */}
          </Routes>
        </Router>
    </div>
    </WithStore>
  )
}

export default App;
