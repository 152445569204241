import "./index.css";
import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import VideoCard from './VideoCard';
import TextField from '@mui/material/TextField';
import { sendPostRequest } from "../../../utils/request";
import { useStore } from "../../../components/ReactHooks/useStore";
import { notification } from 'antd';

const availableBehaviors = [
    {
        voice: "en-US-AvaNeural",
        prompt: "You are a helpful assistant that likes complimenting users. " 
        + "When you answer user's questions, don't forget to compliment user as well. "
        + "Ensure that the output word count is between 50 and 100."
    },
    {
        voice: "en-US-AndrewNeural",
        prompt: "You are a helpful assistant that likes making humorous jokes. " 
        + "When you answer user's questions, don't forget to tell users a short joke based on user's question as well. "
        + "Ensure that the output word count is between 50 and 100."
    },
    {
        voice: "en-US-BrianNeural",
        prompt: "You are a poetic and caring assistant that uses poetic way of answering questions. "
        + "When you answer user's questions, remember to be poetic and caring. " 
        + "Ensure that the output word count is between 50 and 100. Don't ever reply to user using the word Hello"
    }
];

export default function OneClick() {
    const [api, contextHolder] = notification.useNotification();
    const [store, ] = useStore();
    const [tabIndex, setTabIndex] = useState(0);
    const [prompt, setPrompt] = useState(availableBehaviors[0].prompt);

    const openNotification = (personality, placement) => {
        api.info({
          message: personality + " Enabled",
          description: "Please dial the phone number again to check out changes",
          placement,
        });
      };

    async function onclickTab(tabIndex) {
        setTabIndex(tabIndex);
        setPrompt(availableBehaviors[tabIndex].prompt);
        
        await sendPostRequest(
            "/v1/user/setBehavior", 
            { 
                "id" : "", 
                "azureVoiceName" : availableBehaviors[tabIndex].voice, 
                "prompt" : availableBehaviors[tabIndex].prompt
            },
            ""
        );
        const personalities = ["Encouraging Ana", "Humorous Andrew", "Poet Brian"];
        openNotification(personalities[tabIndex], 'topRight');
    }

    function initPrompt() {
        const azureVoiceName = (store.context && store.context.behavior && store.context.behavior.azureVoiceName) || "en-US-AvaNeural";
        const idx = availableBehaviors.findIndex(x => x.voice === azureVoiceName);
        setTabIndex(idx);
        setPrompt(availableBehaviors[idx].prompt);
    }

    useEffect(() => {
        initPrompt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [store.context]);
      
    return (
      <div className="one-click-page">
        {contextHolder}
        <Grid container spacing={2}>
            <Grid item xs={6}>
            <Box sx={{ flexGrow: 1 }}>
                <Typography 
                    variant="h2" 
                    component="div" 
                    sx={{ flexGrow: 1, marginTop: "100px", marginLeft: "120px", fontWeight: "bold" }}
                >
                    Voice AI Assistant
                </Typography>
                <div style={{marginTop: "25px"}}>  </div>
                {["Your voice ai butler is literally just one click way. ",
                "Claim a phone number today with us and start having",
                "your own telephony ai assistant. The telephony ai ",
                " assistant can be adjusted accordingly based on your ",
                " own choice. Dial the demo number and feel free to ",
                " choose an example personality on the right ;) "].map(text => (
                    <Typography 
                        variant="h5" 
                        component="div" 
                        sx={{ flexGrow: 1, marginTop: "0px", marginLeft: "130px" }}
                    >
                        {text}
                    </Typography>
                ))}
                </Box>
            </Grid>
            <Grid item xs={6}>
            <Box>
                <Typography 
                    variant="h5" 
                    component="div" 
                    sx={{ flexGrow: 1, marginTop: "90px", marginLeft: "100px" }}
                >
                    {"Demo number: +1 (781) 773 4045"}
                </Typography>
                <Tabs
                    sx={{ marginTop: "20px", marginLeft: "100px" }}
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        onclickTab(newValue);
                    }}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    >
                    <Tab value={0} label="Encouraging Ana" />
                    <Tab value={1} label="Humorous Andrew" />
                    <Tab value={2} label="Poet Brian" />
                </Tabs>
                <TextField
                    className="prompt-box"
                    sx={{ 
                        color: "secondary",
                        flexGrow: 1, 
                        marginTop: "10px", 
                        marginLeft: "90px",
                        width: "500px"
                     }}
                    placeholder="Enter your prompt here"
                    multiline
                    value={prompt}
                    rows={12}
                    disabled={true}
                    maxRows={100}
                />
            </Box>
            </Grid>
        </Grid>
      </div>
    );
}