import React from "react";
import "./Headbar.css";
// import { HeadbarToggle0, HeadbaarToggle1 } from "../Widgets/svg";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Menu } from '@mui/material';
import Box from '@mui/material/Box';
import HeadbarDropdown from "./HeadbarDropdown";
// import { useStore } from "../ReactHooks/useStore";

export default function Headbar({ 
  isSidebarExtended,
  setIsSidebarExtended
}) {
  // const [store, setStore] = useStore();

  return (
    <>
    <div className="banner">
      <Typography 
        align="center"
        variant="h7" 
        component="div" 
        sx={{ flexGrow: 1, marginTop: "10px" }}
      >
          More features of customer support coming up: food ordering, appointment maker, emotion-focused therapy etc.
      </Typography>
    </div>

    <div className="headbar">
      <AppBar position="static" sx={{display : "flex", width: "100%"}}>
        <Toolbar>
            <img style={{ marginLeft: "40px"}} className="logo-holder" src="/icon.png" alt="" sx={{ flexGrow: 1 }}></img>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
              Cater
            </Typography>
            <div style={{ marginRight: "20px"}}>
              <HeadbarDropdown
                parentText={"Partners"}
                childrenItems={[
                  { text: "Muncho", url: "https://www.muncho.io/" }
                ]}
              />
            </div>
            <div style={{ marginRight: "20px"}}>
              <HeadbarDropdown
                parentText={"Integrations"}
                childrenItems={[
                  { text: "Azure", url: "https://azure.microsoft.com/en-us" },
                  { text: "Whisper", url: "https://openai.com/research/whisper" }
                ]}
              />
            </div>
            <div style={{ marginRight: "660px"}}>
              <HeadbarDropdown 
                parentText={"Contact"}
                childrenItems={[
                  { text: "Johnny Su", url: "mailto:cosmobiosis@gmail.com" }
                ]}
              />
            </div>
            {/* <Button 
              className="login" 
              variant="contained">
                Sign Up
            </Button> */}
        </Toolbar>
      </AppBar>
    </div>
    </>
  );
}
